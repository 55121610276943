import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import RevokeScreenFile from "./RevokeScreenFile/RevokeScreenFile";

const Root = styled.section`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 350px;

  & > * {
    margin-bottom: 13px;
  }
`;

function RevokeScreenFileList({ files, onFileToggle, isDisabled }) {
  function handleFileToggle(id) {
    onFileToggle(id);
  }

  return (
    <Root>
      {files.map((file, idx) => (
        <RevokeScreenFile
          onToggle={handleFileToggle}
          key={idx}
          index={idx}
          isDisabled={isDisabled}
          {...file}
        ></RevokeScreenFile>
      ))}
    </Root>
  );
}

RevokeScreenFileList.propTypes = {
  isDisabled: PropTypes.bool,
  onFileToggle: PropTypes.func,
  files: PropTypes.array,
};

RevokeScreenFileList.defaultProps = {
  isDisabled: false,
  onFileToggle: () => {},
  files: [],
};

export default RevokeScreenFileList;
