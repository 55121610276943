import Checkbox from "@material-ui/core/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import React from "react";

function RoundCheckBox(props) {
  return (
    <Checkbox
      color="primary"
      icon={<CircleUnchecked />}
      checkedIcon={<CircleCheckedFilled />}
      {...props}
    ></Checkbox>
  );
}

export default RoundCheckBox;
