import axios from "axios";

/**
 * Update files state in BE to make unshared files as snoozed
 *
 * @export
 * @param {*} apiId
 * @param {*} issueId
 * @param {*} profileId
 * @param {*} allFilesIds - all files ids received (even unselected ones)
 * @param {*} revokedFileIds - successfully unshared file ids
 */
export async function updateFilesState(
  apiId,
  issueId,
  profileId,
  allFilesIds = [],
  revokedFileIds = []
) {
  await axios.post(
    `https://${apiId}.execute-api.us-east-1.amazonaws.com/prodapi/user-service/authfree/v1/files-state`,
    {
      closedFiles: revokedFileIds,
      allFiles: allFilesIds,
      profileId,
      issueUniqueKey: issueId,
    }
  );
}

// https://${apiId}.execute-api.us-east-1.amazonaws.com/prodapi/user-service/authfree/v1/files-state
