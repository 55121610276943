import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import GoogleRevokeScreen from "../../google/components/GoogleRevokeScreen";
import Home from "../../home/components/Home";
import OfficeRevokeScreen from "../../office/components/OfficeRevokeScreen";

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/office" component={OfficeRevokeScreen}></Route>
        <Route path="/google" component={GoogleRevokeScreen}></Route>
        <Route component={Home}></Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
