import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Root = styled.h1`
  display: flex;
  justify-content: flex-start;
  padding: 0 2rem;
  border-bottom: 1px solid #ccc;
  height: 80px;
  align-items: center;
`;

const ImageLogo = styled.img`
  height: 37px;
  width: 40px;
  object-fit: none;
`;

const Title = styled.div`
  margin-left: 16px;
  font-family: Inter-Regular;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`;

function RevokeScreenTitle({ title }) {
  return (
    <Root>
      <ImageLogo src={`${process.env.PUBLIC_URL}/img/logo.svg`} />
      <Title>Revoke externally shared {title} files</Title>
    </Root>
  );
}

RevokeScreenTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default RevokeScreenTitle;
