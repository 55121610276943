export async function revokeFilePermission(fileId, permissionIds) {
  await Promise.all(
    permissionIds.map(async (permissionId) => {
      console.debug(
        `\t revoking fileId=${fileId}, permissionId=${permissionId}`
      );
      // call gapi to revoke
      await window.gapi.client.drive.permissions.delete({
        permissionId,
        fileId,
      });
    })
  );
}
