import * as msal from "@azure/msal-browser";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalRequest, MSAL_CONFIG } from "./config";

// Create the main MSAL instance
// configuration parameters are located in config.js
let msalClient
function getMSALClient() {
    if (!msalClient) {
        msalClient = new PublicClientApplication(MSAL_CONFIG);
    }
    return msalClient
}

export async function signIn() {
  // Login
  try {
    // Use MSAL to login
    const msalClient = getMSALClient()
    const authResult = await msalClient.loginPopup(msalRequest);
    console.log("id_token acquired at: " + new Date().toString());
    // Save the account username, needed for token acquisition
    sessionStorage.setItem("msalAccount", authResult.account.username);
    return true
  } catch (error) {
    console.log(error);
    return false
  }
}

export function signOut() {
  const msalClient = getMSALClient()
  msalClient.logout();
}

export async function getToken() {
  let account = sessionStorage.getItem("msalAccount");
  if (!account) {
    throw new Error(
      "User account missing from session. Please sign out and sign in again."
    );
  }

  try {
    // First, attempt to get the token silently
    const msalClient = getMSALClient()
    const silentRequest = {
      scopes: msalRequest.scopes,
      account: msalClient.getAccountByUsername(account),
    };

    const silentResult = await msalClient.acquireTokenSilent(silentRequest);
    const { accessToken = {} } = silentResult
    return accessToken;
  } catch (silentError) {
    // If silent requests fails with InteractionRequiredAuthError,
    // attempt to get the token interactively
    if (silentError instanceof msal.InteractionRequiredAuthError) {
      const interactiveResult = await msalClient.acquireTokenPopup(msalRequest);
      return interactiveResult.accessToken;
    } else {
      throw silentError;
    }
  }
}
