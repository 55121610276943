import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import RevokeScreenFileList from "./RevokeScreenFileList/RevokeScreenFileList";
import RevokeScreenSelectAll from "./RevokeScreenSelectAll";

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectMessage = styled.div`
  font-size: 15px;
  padding-top: 11px;
  font-family: "Inter-SemiBold";
`;

function RevokeScreenBody({
  files,
  isDisabled,
  isSelectedAll,
  onSelectAll,
  onFileToggle,
}) {
  return (
    <Root>
      <SelectMessage>
        Please select the unneeded shares to remove:
      </SelectMessage>
      <RevokeScreenSelectAll
        disabled={isDisabled}
        isSelectedAll={isSelectedAll}
        onClick={onSelectAll}
      ></RevokeScreenSelectAll>

      <RevokeScreenFileList
        isDisabled={isDisabled}
        onFileToggle={onFileToggle}
        files={files}
      ></RevokeScreenFileList>
    </Root>
  );
}

RevokeScreenBody.propTypes = {
  files: PropTypes.array,
  isDisabled: PropTypes.bool,
  isSelectedAll: PropTypes.bool,
  onSelectAll: PropTypes.func,
  onFileToggle: PropTypes.func,
};

RevokeScreenBody.defaultProps = {
  files: [],
  onSelectAll: () => {},
  onFileToggle: () => {},
};

export default RevokeScreenBody;
