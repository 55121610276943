/**
 * Init the gapi client
 *
 * @export
 */
export async function initClient() {
  console.log(`init gapi client: ${process.env.REACT_APP_GOOGLE_CLIENT_ID}`);
  await window.gapi.client.init({
    discoveryDocs: [
      "https://content.googleapis.com/discovery/v1/apis/drive/v3/rest",
    ],
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    scope: "https://www.googleapis.com/auth/drive",
  });
}
