import * as MicrosoftGraph from "@microsoft/microsoft-graph-client";
import { getToken } from "./auth";

//console.log(msal);

// Create an authentication provider
const AUTH_PROVIDER = {
  getAccessToken: async () => {
    // Call getToken in auth.js
    return await getToken();
  },
};



// Initialize the Graph client
let graphClient
function getGraphClient() {
    if (!graphClient) {
        graphClient = MicrosoftGraph.Client.initWithMiddleware({ authProvider:AUTH_PROVIDER });
    }
    return graphClient
}

export async function removeSharedFile(itemId, permissionIds = []) {
  const promises = permissionIds.map(async (permissionId) => {
    await removeFilePermission(itemId, permissionId);
  });
  try {
    await Promise.all(promises);
    return true;
  }
  catch(err) {
      console.error(err)
      return false;
      //throw new Error(`failed to remove permission for item ${itemId}`)
  }
}

async function removeFilePermission(itemId, permissionId) {
    // DELETE /me/drive/items/{item-id}/permissions/{perm-id}
    console.log(`/me/drive/items/${itemId}/permissions/${permissionId}`)
    const graphClient = getGraphClient()
    await graphClient
      .api(`/me/drive/items/${itemId}/permissions/${permissionId}`)
      .delete();
    return true
}
