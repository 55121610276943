import { filter } from "lodash";
import React, { useState, useEffect } from "react";
import RevokeScreen from "../../revoke-screen/components/revoke-screen/RevokeScreen";
import { signIn } from "./auth";
import { removeSharedFile } from "./graph";
import { parse } from "qs";

function OfficeRevokeScreen() {
  const [files, setFiles] = useState([]);
  const [customerDomain, setCustomerDomain] = useState();
  const [errorMessage] = useState();
  const [state, setState] = useState();

  useEffect(() => {
    // TODO: move to hook
    if (window.location.search.length) {
      const query = parse(window.location.search.slice(1));
      // set files
      const { files = [], customerDomain } = query;
      setFiles(() => files.map((file) => ({ ...file, isSelected: true })));
      setCustomerDomain(customerDomain);
    }
  }, []);

  function handleFilesChange(newFiles) {
    setFiles(newFiles);
  }

  async function handleRevoke(files) {
    const selectedFiles = filter(files, { isSelected: true });

    console.debug(
      `office revoke files: ${JSON.stringify(selectedFiles, null, 2)}`
    );

    let succeededSignin = await signIn();

    if (succeededSignin) {
      let success = false;
      for (const selectedFile of selectedFiles) {
        setState("in_progress");
        const { meta: { id, permissionIds } = {} } = selectedFile;
        if (id && permissionIds && permissionIds.length) {
          let fileSuccess = await removeSharedFile(id, permissionIds);
          if (fileSuccess) {
            success = true;
          }
        }
      }
      if (!success) {
        setState("error");
      }
      if (success) {
        setState("success");
      }
      console.debug(`success: ${success}`);
    }
  }

  return (
    <RevokeScreen
      customerDomain={customerDomain}
      title={"Microsoft OneDrive"}
      onRevoke={handleRevoke}
      state={state}
      files={files}
      onFilesChange={handleFilesChange}
      errorMessage={errorMessage}
    ></RevokeScreen>
  );
}

export default OfficeRevokeScreen;
