import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Root = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MaterialButton = styled(Button)`
  text-transform: none !important;
  text-align: center;
  flex: 1;
`;

function KeepSharesOpenButton({ onClick, isDisabled }) {
  return (
    <Root>
      <MaterialButton
        disabled={isDisabled}
        onClick={onClick}
        variant="contained"
        color="primary"
      >
        Keep shares open for another 60 days
      </MaterialButton>
    </Root>
  );
}

KeepSharesOpenButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

KeepSharesOpenButton.defaultProps = {
  isDisabled: false,
  onClick: () => {},
};

export default KeepSharesOpenButton;
