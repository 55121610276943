import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Root = styled.section`
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Description = styled.div`
  margin: 2rem 0;
  font-family: "Inter-Regular";
  font-size: 15px;

  & > * {
    margin: 0;
  }
`;

function RevokeScreenMessage({ customerDomain }) {
  return (
    <Root>
      <Description>
        <p>Hello,</p>
        <br></br>
        <p>
          The security administrator at <b>{customerDomain}</b> has requested
          that you revise
        </p>
        <p>
          the following externally shared files and remove the sharing of the
          unneeded ones:
        </p>
      </Description>
    </Root>
  );
}

RevokeScreenMessage.propTypes = {
  customerDomain: PropTypes.string,
};

RevokeScreenMessage.defaultProps = {
  customerDomain: "wing.security",
};

export default RevokeScreenMessage;
