import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Root = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MaterialButton = styled(Button)`
  text-transform: none !important;
  text-align: center;
  flex: 1;
`;

function RevokeButton({ numSelected, onClick, isDisabled }) {
  return (
    <Root>
      <MaterialButton
        disabled={isDisabled}
        onClick={onClick}
        variant="contained"
        color="primary"
      >
        {`Remove ${numSelected} selected shares`}
      </MaterialButton>
      {!isDisabled && (
        <Typography variant="caption" display="block" style={{ marginTop: 4 }}>
          Clicking above will grant a temporary permission to close file shares
          that you own
        </Typography>
      )}
    </Root>
  );
}

RevokeButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  numSelected: PropTypes.number,
};

RevokeButton.defaultProps = {
  isDisabled: false,
  onClick: () => {},
  numSelected: 0,
};

export default RevokeButton;
