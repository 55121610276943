import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import KeepSharesOpenButton from "./KeepSharesOpenButton";
import RevokeButton from "./RevokeButton";

const Root = styled.div`
  margin-bottom: 20px;
`;

const Message = styled.div``;

function RevokeScreenActions({
  customerDomain,
  isDisabled,
  numProccessedFiles,
  numSelected,
  onRevokeClick,
  onKeepSharesClick,
  state,
}) {
  return (
    <Root>
      <RevokeButton
        isDisabled={isDisabled || numSelected === 0}
        onClick={() => onRevokeClick()}
        numSelected={numSelected}
      ></RevokeButton>
      {numSelected === 0 && (
        <KeepSharesOpenButton
          isDisabled={isDisabled}
          onClick={() => onKeepSharesClick()}
        ></KeepSharesOpenButton>
      )}
      <Message>
        {state === "in_progress" && (
          <span>{`Please wait... handled ${numProccessedFiles}/${numSelected} files`}</span>
        )}
        {state === "success" && (
          <span>{`Successfully unshared ${numSelected} files`}</span>
        )}
        {state === "error" && (
          <span>{`Failed removing shares. Please refresh and try again. Make sure you log in using your ${
            customerDomain ? customerDomain : ""
          } account.`}</span>
        )}
      </Message>
    </Root>
  );
}

RevokeScreenActions.propTypes = {
  customerDomain: PropTypes.string,
  isDisabled: PropTypes.bool,
  numProccessedFiles: PropTypes.number,
  numSelected: PropTypes.number,
  onRevokeClick: PropTypes.func,
  onKeepSharesClick: PropTypes.func,
  state: PropTypes.string,
};

RevokeScreenActions.defaultProps = {
  isDisabled: false,
  numProccessedFiles: 0,
  numSelected: 0,
  onRevokeClick: () => {},
  onKeepSharesClick: () => {},
};

export default RevokeScreenActions;
