import { every, filter } from "lodash";
import { parse } from "qs";
import React, { useEffect, useState } from "react";
import RevokeScreen from "../../revoke-screen/components/revoke-screen/RevokeScreen";
import { revokeFilePermission } from "../utils/revoke-file-permissions";
import { updateFilesState } from "../utils/update-files-state";
import { initClient } from "./google_client";

function GoogleRevokeScreen() {
  const [files, setFiles] = useState([]);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [state, setState] = useState();
  const [numProcessedFiles, setNumProcessedFiles] = useState(0);
  const [queryString, setQueryString] = useState({});

  // init google client
  useEffect(() => {
    console.log("load gapi");
    // init client
    window.gapi.load("client:auth2", initClient);

    return () => {
      window.gapi.auth2.getAuthInstance().disconnect();
      window.gapi.auth2.getAuthInstance().signOut();
    };
  }, []);

  // parse query string
  useEffect(() => {
    // TODO: move to hook
    if (window.location.search.length) {
      const queryString = parse(window.location.search.slice(1));

      // set query string
      setQueryString(queryString);

      // set files
      const { files = [] } = queryString;
      setFiles(() =>
        files.map((file) => ({ ...file, isSelected: true, revokeStatus: null }))
      );
    }
  }, []);

  function handleFilesChange(newFiles) {
    setFiles(newFiles);
  }

  async function handleRevoke(files) {
    const authInstance = window.gapi.auth2.getAuthInstance();
    // make sure the user is logged in, if not - sign it
    if (!window.gapi.auth2.getAuthInstance().isSignedIn.get() && !isSignedIn) {
      await authInstance.signIn();
      setIsSignedIn(authInstance.isSignedIn.get());
    }

    // make sure at least one file is selected
    let selectedFiles = filter(files, { isSelected: true });

    // process selected files
    setState("in_progress");
    selectedFiles = await Promise.all(
      selectedFiles.map(async (file) => {
        const {
          meta: { id: fileId, permissionIds = [] },
        } = file;
        let isRevoked = false;

        console.debug(
          `fileId=${fileId}, permissionIds=${JSON.stringify(permissionIds)}`
        );

        try {
          await revokeFilePermission(fileId, permissionIds);
          isRevoked = true;
        } catch (err) {
          // fail silently
          console.debug(err);
        } finally {
          setNumProcessedFiles((numProcessedFiles) => numProcessedFiles + 1);
        }

        return {
          ...file,
          isRevoked,
        };
      })
    );

    // change status according to result
    const isSuccess = every(selectedFiles, ["isRevoked", true]);
    setState(isSuccess ? "success" : "error");

    const { apiId, issueId, targetProfile } = queryString;

    if (apiId && issueId && targetProfile) {
      const revokedFileIds = selectedFiles
        .filter((file) => file.isRevoked === true)
        .map(({ meta: { id: fileId } }) => fileId);

      const allFileIds = files.map(({ meta: { id: fileId } }) => fileId);

      console.debug(
        `all=${JSON.stringify(allFileIds)}, revoked=${JSON.stringify(
          revokedFileIds
        )}`
      );

      // snooze unshared files
      await updateFilesState(
        apiId,
        issueId,
        targetProfile,
        allFileIds,
        revokedFileIds
      );
    }
  }

  async function handleKeepShares(files) {
    try {
      const { apiId, issueId, targetProfile } = queryString;

      if (apiId && issueId && targetProfile) {
        const allFileIds = files.map(({ meta: { id: fileId } }) => fileId);

        // snooze unshared files
        await updateFilesState(
          apiId,
          issueId,
          targetProfile,
          allFileIds,
          [] // nothing was shared
        );
      }
      setState("success");
    } catch (err) {
      setState("error");
    }
  }

  return (
    <RevokeScreen
      numProccessedFiles={numProcessedFiles}
      customerDomain={queryString.customerDomain}
      onFilesChange={handleFilesChange}
      files={files}
      title="Google Drive"
      onRevoke={handleRevoke}
      onKeepShares={handleKeepShares}
      state={state}
    ></RevokeScreen>
  );
}

export default GoogleRevokeScreen;
