import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: Inter;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #191919;
  margin-bottom: 6px;
`;

const Body = styled.div`
  opacity: 0.7;
  font-family: Inter;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  word-wrap: break-word;
`;

function RevokeScreenFileData({ title, children }) {
  return (
    <Root>
      <Title>{title}</Title>
      <Body>{children}</Body>
    </Root>
  );
}

RevokeScreenFileData.propTypes = {
  title: PropTypes.string.isRequired,
};

RevokeScreenFileData.defaultProps = {
  children: <span>-</span>,
};

export default RevokeScreenFileData;
