import Link from "@material-ui/core/Link";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import media from "styled-media-query";
import RoundCheckBox from "../../../../../../common/components/RoundCheckBox";
import RevokeScreenFileData from "./RevokeScreenFileData";

export const fileRowStyle = css`
  padding: 24px;
  border-radius: 4px;
  background-color: #ffffff;
`;

const Root = styled.div`
  ${fileRowStyle}
  display: grid;
  border: solid 1px #e5e5e5;
  box-shadow: 0 2px 4px 0 rgba(25, 25, 25, 0.04);

  grid-template-columns: 60px 100px 200px repeat(3, 1fr);
  grid-column-gap: 0.5rem;
  grid-template-rows: 100%;

  ${media.lessThan("medium")`
    grid-template-rows: repeat(6,1fr);
    grid-template-columns: 100%;
    grid-gap: 10px;
  `}
`;

const Column = styled.div``;

const CenteredColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function RevokeScreenFile({
  fileName,
  fileNameLink,
  highestRole,
  index,
  isDisabled,
  isSelected,
  lastExternalView,
  lastView,
  onToggle,
  sharedWith,
}) {
  function handleCheckBoxChange() {
    onToggle(index);
  }

  return (
    <Root>
      <CenteredColumn>
        <RoundCheckBox
          onChange={() => handleCheckBoxChange(index)}
          checked={isSelected}
          disabled={isDisabled}
        ></RoundCheckBox>
      </CenteredColumn>
      <Column>
        <RevokeScreenFileData title="File Name">
          <Link target="_blank" rel="noopener noreferrer" href={fileNameLink}>
            {fileName}
          </Link>
        </RevokeScreenFileData>
      </Column>
      <Column>
        <RevokeScreenFileData title="Shared With">
          {sharedWith.map((val, idx) => (
            <div key={idx}>{val}</div>
          ))}
        </RevokeScreenFileData>
      </Column>
      <Column>
        <RevokeScreenFileData title="Highest Role">
          {highestRole}
        </RevokeScreenFileData>
      </Column>
      <Column>
        <RevokeScreenFileData title="Last View">
          {lastView ? moment(lastView).format("YYYY-MM-DD HH:MM") : "-"}
        </RevokeScreenFileData>
      </Column>
      <Column>
        <RevokeScreenFileData title="Last External View">
          {lastExternalView
            ? moment(lastExternalView).format("YYYY-MM-DD HH:MM")
            : "-"}
        </RevokeScreenFileData>
      </Column>
    </Root>
  );
}

RevokeScreenFile.propTypes = {
  isDisabled: PropTypes.bool,
  fileName: PropTypes.string,
  fileNameLink: PropTypes.string,
  highestRole: PropTypes.string,
  index: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  lastExternalView: PropTypes.string,
  lastView: PropTypes.string,
  onToggle: PropTypes.func,
  sharedWith: PropTypes.arrayOf(PropTypes.string),
};

RevokeScreenFile.defaultProps = {
  isDisabled: false,
  isSelected: false,
  fileNameLink: "#",
  sharedWith: ["-"],
  onToggle: () => {},
};

export default RevokeScreenFile;
