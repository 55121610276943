import { Container } from "@material-ui/core";
import { filter } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import RevokeScreenActions from "./RevokeScreenActions/RevokeScreenActions";
import RevokeScreenBody from "./RevokeScreenBody/RevokeScreenBody";
import RevokeScreenHeader from "./RevokeScreenHeader/RevokeScreenHeader";
import RevokeScreenTitle from "./RevokeScreenTitle/RevokeScreenTitle";

const Root = styled.div`
  min-height: 100vh;

  ${media.lessThan("medium")`
    margin: 1rem 0;
  `}

  ${media.greaterThan("medium")`
    display: flex;
    justify-content: center;
    align-items: center;
`}
`;

const Screen = styled.div`
  border: 1px solid #dadce0;
  border-radius: 8px;
`;

const BodyWrapper = styled.div`
  padding: 0 40px;
`;

function RevokeScreen({
  onRevoke,
  onKeepShares,
  title,
  state,
  files,
  customerDomain,
  onFilesChange,
  numProccessedFiles,
}) {
  const numSelected = filter(files, (f) => f.isSelected === true).length;
  const isSelectedAll = files.length > 0 && numSelected === files.length;

  // disable components in certain conditions
  const isDisabled =
    files === undefined ||
    files.length === 0 ||
    state === "success" ||
    state === "error" ||
    state === "in_progress";

  function handleFileToggle(fileIndex) {
    const newFiles = [...files];
    newFiles[fileIndex].isSelected = !!!newFiles[fileIndex].isSelected;
    onFilesChange(newFiles);
  }

  function handleSelectAllClick() {
    onFilesChange(
      files.map((file) => ({ ...file, isSelected: !isSelectedAll }))
    );
  }

  return (
    <Root>
      <Container maxWidth="md">
        <Screen>
          <RevokeScreenTitle title={title}></RevokeScreenTitle>
          <BodyWrapper>
            <RevokeScreenHeader
              customerDomain={customerDomain}
            ></RevokeScreenHeader>
            <RevokeScreenBody
              files={files}
              isDisabled={isDisabled}
              isSelectedAll={isSelectedAll}
              onSelectAll={handleSelectAllClick}
              onFileToggle={handleFileToggle}
            ></RevokeScreenBody>
            <RevokeScreenActions
              numProccessedFiles={numProccessedFiles}
              onRevokeClick={() => onRevoke(files)}
              onKeepSharesClick={() => onKeepShares(files)}
              state={state}
              numSelected={numSelected}
              isDisabled={isDisabled}
            ></RevokeScreenActions>
          </BodyWrapper>
        </Screen>
      </Container>
    </Root>
  );
}

RevokeScreen.propTypes = {
  numProccessedFiles: PropTypes.number,
  customerDomain: PropTypes.string,
  files: PropTypes.array,
  onFilesChange: PropTypes.func,
  onRevoke: PropTypes.func,
  onKeepShares: PropTypes.func,
  state: PropTypes.oneOf(["success", "in_progress", "error"]),
  title: PropTypes.string,
};

RevokeScreen.defaultProps = {
  numProccessedFiles: 0,
  files: [],
  onFilesChange: () => {},
  onRevoke: () => {},
  onKeepShares: () => {},
};

export default RevokeScreen;
