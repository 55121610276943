import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RevokeScreenMessage from "./RevokeScreenMessage";

const Root = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const LogoImage = styled.img``;

function RevokeScreenHeader({ customerDomain }) {
  const [logoSource, setLogoSource] = useState(null);

  useEffect(() => {
    setLogoSource(`https://logo.clearbit.com/${customerDomain}?height=110`);
  }, [customerDomain]);

  function handleLogoError() {
    setLogoSource(null);
  }

  return (
    <Root>
      <LogoImage onError={handleLogoError} src={logoSource} alt=""></LogoImage>
      <RevokeScreenMessage
        customerDomain={customerDomain}
      ></RevokeScreenMessage>
    </Root>
  );
}

export default RevokeScreenHeader;

RevokeScreenHeader.propTypes = {
  customerDomain: PropTypes.string,
};

RevokeScreenHeader.defaultProps = {};
