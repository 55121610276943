import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#2a2b50",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#ad295b",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});
