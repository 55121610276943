import { ThemeProvider } from "@material-ui/core/styles";
import Router from "./common/components/Router";
import { theme } from "./theme";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router></Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
