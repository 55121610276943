import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import RoundCheckBox from "../../../../common/components/RoundCheckBox";
import { fileRowStyle } from "./RevokeScreenFileList/RevokeScreenFile/RevokeScreenFile";

const Root = styled.div`
  ${fileRowStyle}
  display: flex;
`;

const CheckBoxWrapper = styled.div`
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectButton = styled(Button)`
  text-transform: none !important;
  font-size: 1rem;
`;

function RevokeScreenSelectAll({ onClick, isSelectedAll, disabled }) {
  return (
    <Root onClick={() => onClick()}>
      <CheckBoxWrapper>
        <RoundCheckBox
          disabled={disabled}
          checked={isSelectedAll}
        ></RoundCheckBox>
      </CheckBoxWrapper>
      <SelectButton disabled={disabled} color="primary">
        Select All
      </SelectButton>
    </Root>
  );
}

RevokeScreenSelectAll.propTypes = {
  disabled: PropTypes.bool,
  isSelectedAll: PropTypes.bool,
  onClick: PropTypes.func,
};

RevokeScreenSelectAll.defaultProps = {
  disabled: false,
  isSelectedAll: false,
  onClick: () => {},
};

export default RevokeScreenSelectAll;
