export const MSAL_CONFIG= {
  auth: {
    clientId: "e644d2ae-e4a7-4de1-8737-e0602abdb7a1",
    //redirectUri: 'http://localhost:8080'
  },
};

export const msalRequest = {
  scopes: ["Files.ReadWrite"],
};
